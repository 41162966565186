<template>
  <el-dialog
    title="问卷关联风险"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="100px"
    >
      <el-form-item
        label="评估问卷"
        prop="questionnaireCode"
        :rules="[ { required: true, message: '问卷不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.questionnaireCode"
          :disabled="saveType === 2 ? true : false"
          filterable
          class="mr10"
          placeholder="请选择问卷"
          @change="form.questionCode= '';form.matchValues=[];getQuestionOptions()"
        >
          <el-option
            v-for="(item,index) in questionaireOptions"
            :key="index"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="题目"
        prop="questionCode"
        :rules="[ { required: true, message: '题目不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.questionCode"
          :disabled="saveType === 2 ? true : false"
          filterable
          class="mr10"
          placeholder="请选择题目"
          @change="questionSelect"
        >
          <el-option
            v-for="(item,index) in questionOptions"
            :key="index"
            :label="item.title"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="匹配值"
        prop="matchValues"
        :rules="[ { required: true, message: '匹配值不能为空', trigger: 'change' }]"
      >
        <el-select
          v-if="answerWay !== 3"
          v-model="form.matchValues"
          class="mr10"
          multiple
          placeholder="请选择匹配值"
        >
          <el-option
            v-for="(c,k) in answerOptions"
            :key="k"
            :label="c.value"
            :value="c.value"
          />
        </el-select>
        <el-input
          v-else
          v-model="form.matchValues[0]"
          placeholder="请输入匹配值"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import {
  saveOrUpdateQuestionRisk,
  GetQuestionnaireOptions, QuestionSelect, QuestionOption,
} from '@/api/assess/questionTemplate'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        id: 0,
        questionCode: '',
        questionnaireCode: '',
        matchValues: '',
        riskCode: this.$route.query.c,
      },
      questionaireOptions: [],
      questionOptions: [],
      questionTypeOptions: [],
      answerOptions: [],
      saveType: 1,
      answerWay: '',
    }
  },
  methods: {
    dialogOpen() {
      this.getQuestionaireOptions()
      if (this.form.questionnaireCode) {
        // this.getQuestionOptions()
        QuestionSelect(this.form.questionnaireCode).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.questionOptions = resData.data
            if (this.questionOptions.length > 0) {
              const currentQuestion = this.questionOptions.filter(item => item.code === this.form.questionCode)
              this.answerWay = currentQuestion[0].answerWay
            }
          }
        })
      }
      if (this.form.questionCode) {
        this.getAnswerOptions()
      }
    },
    questionSelect() {
      const currentQuestion = this.questionOptions.filter(item => item.code === this.form.questionCode)
      this.answerWay = currentQuestion[0].answerWay
      if (this.answerWay === 3) {
        this.form.matchValues = ['']
      } else {
        this.form.matchValues = []
        this.getAnswerOptions()
      }
    },
    getAnswerOptions() {
      QuestionOption(this.form.questionnaireCode, this.form.questionCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.answerOptions = resData.data
        }
      })
    },
    // 获取问卷下拉
    getQuestionaireOptions() {
      GetQuestionnaireOptions({ assessCategoryCode: '' }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.questionaireOptions = resData.data
        }
      })
    },
    // 获取题目下拉
    getQuestionOptions() {
      QuestionSelect(this.form.questionnaireCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.questionOptions = resData.data
        }
      })
    },
    dialogClose() {
      this.form = {
        id: 0,
        questionCode: '',
        questionnaireCode: '',
        matchValues: [],
        riskCode: this.$route.query.c,
      }
      this.$refs.form.resetFields()
      this.questionaireOptions = []
      this.questionOptions = []
      this.answerOptions = []
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          saveOrUpdateQuestionRisk(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
