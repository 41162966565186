<template>
  <div>
    <b-card>
      <div class="flex-between">
        <el-descriptions>
          <el-descriptions-item label="风险名称">
            {{ riskName }}
          </el-descriptions-item>
          <el-descriptions-item label="风险编号">
            {{ dataForm.riskCode }}
          </el-descriptions-item>
        </el-descriptions>
        <el-button
          size="small"
          @click="$router.push({ path: '/risk'})"
        >
          返 回
        </el-button>
      </div>
    </b-card>
    <b-card
      shadow="never"
      class="aui-card--fill"
    >
      <div class="mod-sys__params">
        <el-form
          :inline="true"
          :model="dataForm"
          @keyup.enter.native="getDataList()"
        >
          <div class="flex-between">
            <div class="flex-start">
              <el-form-item>
                <el-input
                  v-model="dataForm.searchStr"
                  placeholder="搜索关键字"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="getDataList()"
                >
                  搜索
                </el-button>
              </el-form-item>
            </div>
            <div class="flex-end">
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  @click="addOrUpdateHandle(1)"
                >
                  新增
                </el-button>
              </el-form-item>
              <!--              <el-form-item>-->
              <!--                <el-button-->
              <!--                  size="small"-->
              <!--                  type="danger"-->
              <!--                  @click="deleteHandle()"-->
              <!--                >-->
              <!--                  删除-->
              <!--                </el-button>-->
              <!--              </el-form-item>-->
            </div>
          </div>

        </el-form>
        <el-table
          v-loading="dataListLoading"
          :data="dataList"
          border
          style="width: 100%;"
          @selection-change="dataListSelectionChangeHandle"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="questionnaireName"
            label="问卷名称"
            header-align="center"
            :show-overflow-tooltip="true"
            align="center"
          />
          <el-table-column
            prop="questionCode"
            label="问卷编号"
            header-align="center"
            align="center"
          />
          <el-table-column
            prop="questionCode"
            :show-overflow-tooltip="true"
            label="题目编号"
            header-align="center"
            align="center"
          />
          <el-table-column
            prop="questionTitle"
            :show-overflow-tooltip="true"
            label="题目名称"
            header-align="center"
            align="center"
          />
          <el-table-column
            prop="matchValue"
            :show-overflow-tooltip="true"
            label="匹配答案"
            header-align="center"
            align="center"
          >
            <template v-slot="scope">
              {{ scope.row.matchValues ? scope.row.matchValues.toString() : '' }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="120"
          >
            <template v-slot="scope">
              <el-link
                :underline="false"
                class="mr10"
                type="primary"
                @click="addOrUpdateHandle(2,scope.row)"
              >
                <el-tooltip
                  content="修改"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link
                :underline="false"
                type="primary"
                class="mr10"
                @click="delRow(scope.row)"
              >
                <el-tooltip
                  content="删除"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="page"
          :limit.sync="pageSize"
          @pagination="query"
        />
        <LinkQuestionSave
          ref="editDialog"
          @handleFilter="query"
        />
      </div>
    </b-card>
  </div>

</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import LinkQuestionSave from '@/views/assess/risk/LinkQuestionSave.vue'
import pagination from '@core/components/pagination/Pagination.vue'
import { RiskMatchDel } from '@/api/assess/questionTemplate'

export default {
  components: { pagination, LinkQuestionSave },
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/risk/match/page',
        getDataListIsPage: true,
        deleteURL: '/risk/match/delete',
        deleteIsBatch: false,
      },
      dataForm: {
        riskCode: this.$route.query.c,
      },
      riskName: this.$route.query.n,
    }
  },
  mounted() {
    this.navActiveInit(1, '风险管理', '评估管理')
  },
  destroyed() {
    this.navActiveInit(2, '风险管理', '评估管理', '/risk')
  },
  methods: {
    delRow(row) {
      this.confirm('确定删除吗?', () => {
        RiskMatchDel({ questionCode: row.questionCode, questionnaireCode: row.questionnaireCode, riskCode: row.riskCode }).then(res => {
          this.handleQuery()
        })
      })
    },
  },
}
</script>
