import {get, post, Delete, downloadByGet} from '@/libs/axios'

export const GetAssessCategoryTree = () => get('/questionnaire/assess/category/tree')
// 查询问题分类
export const GetQuestionCategoryTree = code => get(`/questionnaire/question/category/tree?code=${code}`)
export const QuestionnairePage = params => post('/questionnaire/Page', params)
export const QuestionnaireCDelete = code => Delete(`/questionnaire/Delete?code=${code}`)
export const GetDetailsInfo = code => get(`/questionnaire/GetDetailsInfo?code=${code}`)
export const GetQuestionDetail = id => get(`/questionnaire/question/info?questionId=${id}`)
export const GetQuestionByCategory = (code, categoryCode) => get(`/questionnaire/question/list?code=${code}&categoryCode=${categoryCode}`)
export const GetQuestionSelectOption = params => post('/questionnaire/question/GetQuestionSelectOption', params)
export const GetChildQuestionList = params => post('/questionnaire/question/GetChildQuestionList', params)
export const GetQuestionnaireOptions = params => post('/questionnaire/GetQuestionnaireOptions', params)
export const GeQuestionListByQuestionnaire = params => post('/questionnaire/question/GeQuestionListByQuestionnaire', params)
export const CreateOrUpdateAffiliatedInfo = params => post('/questionnaire/question/affiliated/save', params)
export const DeleteQuestionCategory = (code, categoryCode) => Delete(`/questionnaire/question/category/delete?code=${code}&categoryCode=${categoryCode}`)
export const QuestionSelect = (code, categoryCode, excludeQCodeList) => {
  if (categoryCode === undefined) {
    // eslint-disable-next-line no-param-reassign
    categoryCode = ''
  }
  if (excludeQCodeList === undefined) {
    // eslint-disable-next-line no-param-reassign
    excludeQCodeList = ''
  }
  return get(`questionnaire/question/select?code=${code}&categoryCode=${categoryCode}&excludeQCodeList=${excludeQCodeList}`)
}
export const QuestionOption = (code, qCode) => get(`/questionnaire/question/option/select?code=${code}&qCode=${qCode}`)
export const DeleteAssessCategory = params => post('/questionnaire/assessCategory/DeleteAssessCategory', params)
export const DeleteQuestion = id => Delete(`/questionnaire/question/delete?questionId=${id}`)
export const GetQuestionaireChild = id => get(`/questionnaire/question/child?questionId=${id}`)
export const UpdateFinishStatus = params => post('/questionnaire/UpdateFinishStatus', params)
export const UpdateQusetionStatus = params => post('/questionnaire/UpdateStatus', params)
export const RiskMatchDel = params => post('/risk/match/delete', params)
export const GetOptionBindChildDetails = params => post('/questionnaire/question/GetOptionBindChildDetails', params)
export const SaveOptionBindChildQuestion = (questionId, params) => post(`/questionnaire/question/${questionId}/save_child`, params)
export const GetAffiliateInfo = questionId => get(`/questionnaire/question/affiliated/info?questionId=${questionId}`)
export const GetPreviewInfo = questionCode => get(`/questionnaire/question/GetPreviewInfo?questionCode=${questionCode}`)
export const GetRiskMatchQuestion = id => get(`/questionnaire/QuestionRisk/GetRiskMatchQuestion?id=${id}`)
export const getQuestionTypeInfo = (code, categoryCode) => get(`/questionnaire/question/category/info?code=${code}&categoryCode=${categoryCode}`)
export const GetAssessCategoryInfo = code => get(`/assess/category/info?code=${code}`)
export const saveOrUpdateType = (params, type) => {
  const submitUrl = {
    1: '/questionnaire/assessCategory/CreateAssessCategory',
    2: '/questionnaire/assessCategory/CreateAssessCategory',
    3: '/questionnaire/assessCategory/UpdateAssessCategory',
  }
  return post(submitUrl[type], params)
}
export const saveOrUpdateQuestionType = (params, type) => {
  const submitUrl = {
    1: '/questionnaire/question/category/create',
    2: '/questionnaire/question/category/create',
    3: '/questionnaire/question/category/update',
  }
  return post(submitUrl[type], params)
}
export const saveOrUpdateQuestionnaire = (params, saveType) => {
  const submitUrl = saveType === 2 ? '/questionnaire/Update' : '/questionnaire/Create'
  return post(submitUrl, params)
}
//
export const saveOrUpdateQuestionItem = (params, saveType) => {
  const submitUrl = saveType === 2 ? '/questionnaire/question/update' : '/questionnaire/question/create'
  return post(submitUrl, params)
}
//
export const saveOrUpdateQuestionRisk = params => {
  // const submitUrl = params.id ? '/questionnaire/QuestionRisk/UpdateQuestionRisk' : '/questionnaire/QuestionRisk/CreateQuestionRisk'
  return post('/risk/match/save', params)
}

// 查询问卷信息
export const GetQuestionnaireDetail = code => get(`/questionnaire/GetDetailsInfo?code=${code}`)
export const DownloadQuestionTemplate = () => downloadByGet(`/questionnaire/template/download?_t=${Date.parse(new Date())}`)
export const QuestionImport = params => post('/questionnaire/import', params)
